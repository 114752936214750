import React, { useEffect } from 'react'
import { frog, object2Map, getType } from '@/services/frog'
import NavbarSub from '@/components/Layouts/NavbarSub'
import useWindowResize from '@/utils/useWindowResize'
import { smSize, lgSize, xlSize } from '@/constants/index'
import Comment from '@/components/Home/Comment/Comment'
import productPic from './assets/product.png'
import './specs.less'

export default function Specs() {
  const { windowType } = useWindowResize()
  const windowLg = windowType === 'pc'
  const data = [
    {
      title: '产品图',
      content: [
        <img
          src={productPic}
          className={`specs-product-pic${windowLg ? '-lg' : '-sm'}`}
          alt="小猿学练机产品图"
        />,
      ],
    },
    {
      title: '尺寸与重量',
      content: ['高度：226.06mm', '宽度：188mm', '厚度：6.9mm', '重量：445g'],
    },
    {
      title: '屏幕',
      content: [
        '尺寸：10.3英寸',
        '分辨率：1872X1404 226PPI',
        '类型：墨水屏',
        '屏占比：86.1%',
      ],
    },
    {
      title: '摄像头',
      content: ['后置800W摄像头'],
    },
    {
      title: '处理器',
      content: ['高通八核芯片'],
    },
    {
      title: '内存',
      content: [
        '机身内存：4GB RAM + 64G ROM',
        '云空间：5GB',
        '拓展存储：不支持',
      ],
    },
    {
      title: '网络支持',
      content: ['支持802.11 a/b/g/n/ac+BT5.0(2.4G.5G双模）'],
    },
    {
      title: '系统',
      content: ['Android 11'],
    },
    {
      title: '电池',
      content: ['3.85V / 4000mAh'],
    },
    {
      title: '充电',
      content: ['支持 5V/3A 充电'],
    },
    {
      title: '麦克风',
      content: ['双MIC'],
    },
    {
      title: '扬声器',
      content: ['双扬声器'],
    },
    {
      title: '包装清单',
      content: [
        '学习平板 × 1',
        '电磁笔 × 1',
        '磁吸保护套 × 1',
        '替换笔尖 × 1',
        '充电线  × 1',
      ],
    },
  ]
  const isString = (item: any) => typeof item === 'string'

  useEffect(() => {
    const el = document.getElementById('navbar-sub')
    el?.classList.add('sticky')

    frog('/event/megrezOfficialPage/specification/display', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
  }, [])

  return (
    <div className={windowLg ? 'specs-container-lg' : 'specs-container-sm'}>
      <NavbarSub windowType={windowType} windowLg={windowLg} active="specs" />
      <div className="specs-card-container">
        {data.map((item) => (
          <div className="specs-card">
            <div className="specs-title">{item.title}</div>
            <div className="specs-content">
              {item.content.map((item: any) =>
                isString(item) ? (
                  <div className="specs-content-text">{item}</div>
                ) : (
                  item
                )
              )}
            </div>
          </div>
        ))}
      </div>
      <Comment windowLg={windowLg} commentList={null} />
    </div>
  )
}
