import dev from './dev'
import test from './test'
import online from './online'

const config = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'online':
      return online
    case 'test':
      return test
    default:
      return dev
  }
})()

export default config
