import React, { useEffect, useState } from 'react'
import Navbar from '@/components/Layouts/Navbar'
import NavbarSub from '@/components/Layouts/NavbarSub'
import Banner from '@/components/Home/Banner/Banner'
import FindPaper from '@/components/Home/FindPaper/FindPaper'
import Breakthrough from '@/components/Home/Breakthrough/Breakthrough'
import SyncPractice from '@/components/Home/SyncPractice/SyncPractice'
import LikePaper from '@/components/Home/LikePaper/LikePaper'
import SmartCameraVideo from '@/components/Home/SmartCamera/SmartCameraVideo'
import ListenRead from '@/components/Home/ListenRead/ListenRead'
import DesignDetail from '@/components/Home/DesignDetail/DesignDetail'
import Section53Img from '@/components/Home/53Section/section53Img'
import VideoWithBottomContent from '@/components/Home/Video/VideoWithBottomContent'
import VideoWithModal from '@/components/Home/Video/VideoWithModal'
import Accordion from '@/components/Home/accordion/accordion'
import Reason from '@/components/Home/reason/reason'
import System from '@/components/Home/system/system'
import Specifications from '@/components/Home/Specifications/Specifications'
import Resources from '@/components/Home/Resources/Resources'
import ClosedSystem from '@/components/Home/ClosedSystem/ClosedSystem'
import BookReader from '@/components/Home/BookReader/BookReader'
import FileComponent from '@/components/Home/FileComponent/FileComponent'
import Comment from '@/components/Home/Comment/Comment'
import PracticePaperVideo from '@/components/Home/PracticePaper/PracticePaperVideo'
import MultipleAccounts from '@/components/Home/MultipleAccounts/MultipleAccounts'
import AccompanyRead from '@/components/Home/AccompanyRead/AccompanyRead'
import SolvePuzzles from '@/components/Home/SolvePuzzles/SolvePuzzles'
import { frog, object2Map, getType } from '@/services/frog'
import './home.less'
import { smSize, lgSize, xlSize } from '@/constants/index'
import useWindowResize from '@/utils/useWindowResize'

export default function Home() {
  const { windowWidth, windowType } = useWindowResize()

  useEffect(() => {
    frog('/event/megrezOfficialPage/display', {
      keyValues: object2Map({
        type: getType(),
      }),
    })

    frog('/event/megrezOfficialPage/function/display', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
  }, [])

  return (
    <div className="home-root">
      <Navbar windowType={windowType} windowLg={windowWidth > xlSize} />
      <NavbarSub
        windowType={windowType}
        active="product"
        windowLg={windowWidth > xlSize}
      />
      <Banner windowLg={windowWidth > xlSize} />
      <Reason windowLg={windowWidth > xlSize} />
      <VideoWithModal windowLg={windowWidth > xlSize} />
      <PracticePaperVideo windowLg={windowWidth > xlSize} />
      <LikePaper windowType={windowType} windowLg={windowWidth > xlSize} />
      <VideoWithBottomContent windowLg={windowWidth > xlSize} />
      <Accordion windowLg={windowWidth > xlSize} />
      <System windowLg={windowWidth > xlSize} />
      <SyncPractice windowLg={windowWidth > xlSize} />
      <Breakthrough windowLg={windowWidth > xlSize} />
      <FindPaper windowLg={windowWidth > xlSize} />
      <Resources windowLg={windowWidth > xlSize} />
      <Section53Img />
      <SmartCameraVideo windowLg={windowWidth > xlSize} />
      <FileComponent windowLg={windowWidth > xlSize} />
      <BookReader windowLg={windowWidth > xlSize} />
      <AccompanyRead windowLg={windowWidth > xlSize} />
      <ListenRead windowLg={windowWidth > xlSize} />
      <SolvePuzzles windowLg={windowWidth > xlSize} />
      <DesignDetail windowLg={windowWidth > xlSize} />
      <MultipleAccounts windowLg={windowWidth > xlSize} />
      <ClosedSystem windowLg={windowWidth > xlSize} />
      <Specifications windowLg={windowWidth > xlSize} />
      <Comment windowLg={windowWidth > xlSize} />
    </div>
  )
}
