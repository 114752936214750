/* eslint-disable max-len */
import React, { useRef, useCallback } from 'react'
import { comments } from '@/constants/index'
import useInserted from '@/utils/useInserted'
import './comment.less'

function Comment({
  windowLg,
  ...rest
}: {
  windowLg: boolean
  commentList?: Record<'id' | 'content', string>[] | null
  commentsExtra?: any
}) {
  const commentRef = useRef<any>(null)
  const insertedHandler = useCallback((ratio: number) => {
    if (ratio === 0) {
      const allContainer = document.querySelectorAll('.comments')
      allContainer.forEach((x) => {
        x.classList.remove('selected-content')
      })
    }
  }, [])

  useInserted({
    target: commentRef,
    handler: insertedHandler,
  })

  return (
    <section className="comments-container" ref={commentRef}>
      {rest.commentList !== null ? (
        <div className="comments-main">
          {(rest.commentList ?? comments).map((comment, index) => {
            return (
              <p key={comment.id} id={comment.id} className="comments">
                {`${index + 1}. ${comment.content}`}
              </p>
            )
          })}
        </div>
      ) : null}

      <div className="comments-extra">
        {rest.commentsExtra ? (
          rest.commentsExtra
        ) : (
          <>
            <p>*价格仅供参考，实际价格请以小猿学练机官方销售渠道展示为准。</p>
            <p>
              *以上页面中的产品图片、视频及屏幕内容仅作示意，实物产品效果（包括但不限于外观、颜色、尺寸）和屏幕显示内容（包括但不限于背景、UI、配图、视频）可能略有差异，请以实物为准。
            </p>
            <p>
              *以上页面中的数据为理论值，均由猿力科技智能硬件实验室在特定测试环境下取得，实际使用中可能因产品个体差异、软件版本、使用情况和环境因素等不同而略有不同，请以实际使用的情况为准。
            </p>
            <p>
              *产品信息、规格参数、产品特性可能因产品批次、生产供应因素变化而变化，小猿学练机将力尽所能实时调整和修订以上页面中的文字表述、图片效果等内容，以求与实际产品硬件外观、软件性能、产品规格、内容资源等信息真实匹配。
            </p>
            <p>*如遇确有进行上述修改和调整必要的情形，恕不专门通知。</p>
          </>
        )}
      </div>
    </section>
  )
}

export default React.memo(Comment)
