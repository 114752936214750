import React from 'react'
import LeftImg from './assets/left.png'
import RightImg from './assets/right.png'
import Radio1Img from './assets/radio1.png'
import './ListenRead.less'

export default function ListenRead({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="lr-root-lg">
          {/* <p className='lr-title'>“精”听磨耳朵，“准”读练发音</p> */}
          <h2>“精”听磨耳朵，“准”读练发音</h2>
          <div className="lr-flex">
            <div className="lr-left">
              <img src={LeftImg} alt="语文听写" />
              <div className="lr-radio">
                <img src={Radio1Img} alt="radio1" />
                <img src={Radio1Img} alt="radio2" />
                <img src={Radio1Img} alt="radio3" />
              </div>
              <div className="lr-radio">
                <img src={Radio1Img} alt="radio1" />
                <img src={Radio1Img} alt="radio2" />
                <img src={Radio1Img} alt="radio3" />
              </div>
            </div>
            <div className="lr-right">
              <img src={RightImg} alt="英语听力" />
              <div className="lr-radio">
                <img src={Radio1Img} alt="radio1" />
                <img src={Radio1Img} alt="radio2" />
                <img src={Radio1Img} alt="radio3" />
              </div>
              <div className="lr-radio">
                <img src={Radio1Img} alt="radio1" />
                <img src={Radio1Img} alt="radio2" />
                <img src={Radio1Img} alt="radio3" />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="lr-root-sm">
          {/* <p className='lr-title'>“精”听磨耳朵，“准”读练发音</p> */}
          <h2>“精”听磨耳朵，“准”读练发音</h2>
          <div className="lr-scroll-hidden">
            <div className="lr-scroll-wrap">
              <div className="lr-flex">
                <div className="lr-left">
                  <img src={LeftImg} alt="语文听写" />
                  <div className="lr-radio">
                    <img src={Radio1Img} alt="radio1" />
                    <img src={Radio1Img} alt="radio2" />
                    <img src={Radio1Img} alt="radio3" />
                  </div>
                  <div className="lr-radio">
                    <img src={Radio1Img} alt="radio1" />
                    <img src={Radio1Img} alt="radio2" />
                    <img src={Radio1Img} alt="radio3" />
                  </div>
                </div>
                <div className="lr-right">
                  <img src={RightImg} alt="英语听力" />
                  <div className="lr-radio">
                    <img src={Radio1Img} alt="radio1" />
                    <img src={Radio1Img} alt="radio2" />
                    <img src={Radio1Img} alt="radio3" />
                  </div>
                  <div className="lr-radio">
                    <img src={Radio1Img} alt="radio1" />
                    <img src={Radio1Img} alt="radio2" />
                    <img src={Radio1Img} alt="radio3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
