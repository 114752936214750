import React from 'react'

export default function NewsCard(props: any) {
  const { image, tags, title, date, source, url, windowType } = props
  return (
    <div className="news-card" onClick={() => window.open(url)}>
      <div
        className="news-card-img"
        style={{ backgroundImage: `url(${image})` }}
      />
      {windowType === 'pc' ? (
        <>
          <div className="news-card-tags">
            {tags &&
              tags.map((tag: any, idx: number) => (
                <div className="news-card-tag" key={idx}>
                  {tag}
                </div>
              ))}
          </div>
          <div className="news-card-title">{title}</div>
        </>
      ) : (
        <>
          <div className="news-card-title">{title}</div>
          <div className="news-card-tags">
            {tags &&
              tags.map((tag: any, idx: number) => (
                <div className="news-card-tag" key={idx}>
                  {tag}
                </div>
              ))}
          </div>
        </>
      )}

      <div className="news-card-footer">
        <div className="news-card-date">{date}</div>
        <div className="news-card-source">{source}</div>
      </div>
    </div>
  )
}
