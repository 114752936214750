/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { frog, object2Map, getType } from '@/services/frog'
import {
  navSubHeightXxl,
  navSubHeightLg,
  navSubHeightSm,
  JD_OFFICIAL_S2_LINK,
} from '@/constants/index'
import './NavbarSub.less'

export default function NavbarSub({
  windowType,
  active,
  windowLg,
}: {
  windowLg: boolean
  windowType: string
  active?: string
}) {
  const history = useHistory()

  useEffect(() => {
    frog('/event/megrezOfficialPage/secondNavigation/display', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
  }, [])

  function onClickJD() {
    frog('/click/megrezOfficialPage/secondNavigation/buy', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
    window.open(JD_OFFICIAL_S2_LINK)
  }

  function onClickProduct() {
    frog('/click/megrezOfficialPage/secondNavigation/function', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
    const url = '/'
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (history.location.pathname === url) {
      window.location.reload()
    } else {
      history.push(url)
    }
  }

  function onClickSpecs() {
    frog('/click/megrezOfficialPage/secondNavigation/specification', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
    const url = '/specs'
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (history.location.pathname === url) {
      window.location.reload()
    } else {
      history.push(url)
    }
  }

  return (
    <>
      {windowLg ? (
        <div className="navbar-sub-lg" id="navbar-sub">
          <div className="navbar-sub-main">
            <p className="navbar-sub-title">新品上市</p>
            <nav>
              <ul>
                <li
                  className={active === 'product' ? 'navbar-sub-active' : ''}
                  onClick={onClickProduct}
                >
                  <a>产品功能</a>
                </li>
                <li
                  className={active === 'specs' ? 'navbar-sub-active' : ''}
                  onClick={onClickSpecs}
                >
                  <a>规格参数</a>
                </li>
                <button onClick={onClickJD}>购买</button>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <div className="navbar-sub-sm" id="navbar-sub">
          <div className="navbar-sub-main">
            <p className="navbar-sub-title">新品上市</p>
            <button onClick={onClickJD}>购买</button>
          </div>

          <nav>
            <ul>
              <li className={active === 'product' ? 'navbar-sub-active' : ''}>
                <a onClick={onClickProduct}>产品功能</a>
              </li>
              <li className={active === 'specs' ? 'navbar-sub-active' : ''}>
                <a onClick={onClickSpecs}>规格参数</a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  )
}
