import React from 'react'
import style from './PracticePaperVideo.module.less'

const videoSrc =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20240514/beyond_paper_pc_1715653635704_68d0bc-ca11-f553-3f14-d285657f824c.mp4'
const videoSrcSm =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20240514/beyond_paper_mobil_1715653606912_a4d7bc4-4165-f26-6b0-de7fae63ef1.mp4'

export default function PracticePaperVideo({
  windowLg,
}: {
  windowLg: boolean
}) {
  return (
    <section className={style.container}>
      <h2 className={style.title}>超越纸张 为高效练习而生</h2>
      <video
        className={style.player}
        muted
        autoPlay
        loop
        src={windowLg ? videoSrc : videoSrcSm}
        playsInline
        width="100%"
      />
    </section>
  )
}
