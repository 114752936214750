import { CustomRouteConfig } from '@/types/common'
import View403 from '@/pages/403/403'
import View404 from '@/pages/404/404'
import View500 from '@/pages/500/500'
import routers from './routers'

const baseRouters: CustomRouteConfig[] = [
  {
    path: '/403',
    exact: true,
    component: View403,
  },
  {
    path: '/404',
    exact: true,
    component: View404,
  },
  {
    path: '/500',
    exact: true,
    component: View500,
  },
  {
    path: '*',
    exact: true,
    component: View404,
  },
]

const routes: CustomRouteConfig[] = [...routers, ...baseRouters]

export default routes
