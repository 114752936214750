import { KeyValuePair } from '@/types/common'

export const smSize = 640
export const lgSize = 1024
export const xlSize = 1200
export const xxlSize = 1440

export const navHeightXxl = 58
export const navHeightXl = 48
export const navHeightLg = 65
export const navHeightSm = 48

export const navSubHeightXxl = 64
export const navSubHeightXl = 64
export const navSubHeightLg = 90
export const navSubHeightSm = 73

export const JD_OFFICIAL_MALL_LINK = 'https://mall.jd.com/index-1000409909.html'
export const JD_OFFICIAL_S2_LINK = 'https://3.cn/10bD5R2-a'
export const TAOBAO_OFFICIAL_MALL_LINK = 'https://xiaoyuanznsb.tmall.com'
export const TAOBAO_OFFICIAL_S2_LINK = 'https://s.tb.cn/c.0vNXzz'

export const comments = [
  {
    id: 'certificate',
    content:
      '弗若斯特沙利文于2023年9月对小猿学练机「中国墨水屏学习平板领导者」的市场地位作出研究结果确认并颁发市场地位确认证书。',
  },
  {
    id: 'eink',
    content:
      '小猿学练机采用E-ink电子墨水屏技术。电子墨水屏本身不发光，自然光线经过物理反射进入人眼。「护眼」指与纸质书籍几乎等同的用眼体验，用户使用产品时仍需采用正确的坐姿及保持合理的用眼距离。',
  },
  {
    id: 'pen',
    content:
      '图示手写笔为小猿学练机联合Wacom专门针对中小学生自主研发的定制版电磁笔，无需充电即可使用，笔芯需根据磨损情况及时更换。',
  },
  {
    id: 'ai',
    content:
      '「以练促学」精准学系统是小猿学练机深度应用人工智能技术，通过硬件与软件的创新结合，在实现学习过程、学习内容、学习数据全面数字化的基础上，建立起的一整套全场景个性化学习系统。所有功能、内容、资源、手写支持、智慧拍等产品硬件及软件设计共同构成「以练促学」精准学系统。',
  },
  {
    id: 'blue-ray',
    content:
      '数据来自猿力科技智能硬件实验室。蓝光测试机对纸张、墨水屏、通用手机屏幕三种屏幕介质进行测试，得到的蓝光数值分别为0、0、0.3。',
  },
  {
    id: '4096',
    content:
      '4096级压感需匹配特定应用实现，请以实际体验为准。本产品标配手写笔。',
  },
  {
    id: '861',
    content:
      '数据来源于猿力科技智能硬件实验室。屏占比计算方法为屏幕显示区域/整机正面投影面积，计算结果为86.1%。',
  },
  {
    id: 'nib',
    content:
      '数据来源于猿力科技智能硬件实验室。实际可能受笔尖磨损程度、用力大小等外部因素影响有所差异，请以实际情况为准。',
  },
  {
    id: 'thickness',
    content:
      '数据来源于猿力科技智能硬件实验室。实验室测量产品厚度为6.9±0.2mm，实际厚度依配置、制造工艺、测量方法、批次等不同可能有所差异，请以实际为准。',
  },
  {
    id: 'result',
    content:
      '识别结果可能因软件版本、使用条件、书写清晰度等不同略有不同，请以实际体验为准。',
  },
  {
    id: 'grade',
    content:
      '不同年级、教材版本对应的功能模块、应用数量、应用种类及内容资源有所不同，请以实际为准。',
  },
  {
    id: 'standby',
    content:
      '数据来源于猿力科技智能硬件实验室。阅读灯设定为关闭，Wi-Fi 连接下测试，待机时间可达40天。实际使用中可能因产品个体差异、软件版本、使用情况和环境因素等不同而略有不同，请以实际使用情况为准。',
  },
]
