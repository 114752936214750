import UAParser from 'ua-parser-js'
import Frog from '@yuanfudao/frog'
import { IFrogConfig } from '@yuanfudao/frog/lib/types'
import request from '@/http'
import config from '@/config'

const sendFrog = (url: string, data: any) => {
  return request({
    method: 'POST',
    baseURL: '',
    url,
    data,
  })
}

const getDeviceId = () => {
  try {
    const YFD_U = '-1'
    return YFD_U
  } catch (error) {
    console.error(error)
    return '-1'
  }
}

export const frog = async (url: string, frogConfig: IFrogConfig = {}) => {
  try {
    const YFD_U = getDeviceId()
    Object.assign(frogConfig, {
      YFD_U,
      appVersion: '1.0.0',
    })
    const userid = ''
    const ytkUserId = userid || '-1'
    const keyValue = [{ key: 'userid', value: ytkUserId }]
    const keyValues =
      frogConfig && frogConfig.keyValues
        ? frogConfig.keyValues.concat(keyValue)
        : keyValue

    const frogObj = new Frog({
      ...frogConfig,
      url: config.FROG_HOST,
      send: sendFrog,
      keyValues,
      productId: config.PRODUCT_ID,
      key: `${url}-${Math.random()}`,
    })
    frogObj.send(url)
  } catch (err: any) {
    console.log('err', err)
    throw new Error(err)
  }
}

export interface KeyValueConfig {
  key: string
  value: string
}

export const object2Map = (object: any) => {
  const map: KeyValueConfig[] = []
  Object.keys(object).forEach((key: string) => {
    map.push({
      key,
      value: object[key],
    })
  })
  return map
}

export const getType = () => {
  const parser = new UAParser()
  parser.setUA(window.navigator.userAgent)
  const result = parser.getResult()
  console.log(result)
  if (result && result.device && result.device.type) {
    return 'h5'
  } else {
    return 'web'
  }
}
