import { createStore, applyMiddleware, Store, combineReducers } from 'redux'
import {
  routerMiddleware,
  routerReducer,
  RouterState,
} from 'react-router-redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'

export interface ApplicationState {
  router: RouterState
}

const rootReducer = combineReducers({
  router: routerReducer,
})

const middlewares = [routerMiddleware(createBrowserHistory()), thunk]

function configureStore(initialState?: any): Store<ApplicationState> {
  return createStore(rootReducer, initialState, applyMiddleware(...middlewares))
}

export default class store {
  static _instance: Store

  static get Instance() {
    if (!this._instance) {
      this._instance = configureStore()
    }
    return this._instance
  }
}
