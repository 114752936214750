import React from 'react'
import Pic1Img from './assets/pic1.png'
import Pic2Img from './assets/pic2.png'
import Pic3Img from './assets/pic3.png'
import Pic4Img from './assets/pic4.png'
import Pic1ImgSm from './assets/pic1-sm.png'
import Pic2ImgSm from './assets/pic2-sm.png'
import Pic3ImgSm from './assets/pic3-sm.png'
import Pic4ImgSm from './assets/pic4-sm.png'
import './DesignDetail.less'

export default function DesignDetail({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="dd-root-lg">
          {/* <p className='dd-title'>巧妙设计，细节取胜</p> */}
          <h2>巧妙设计，细节取胜</h2>
          <div className="dd-flex">
            <div className="dd-column-1">
              <img src={Pic1Img} alt="学练机细节" />
            </div>
            <div className="dd-column-2">
              <img src={Pic2Img} alt="学练机细节" />
              <img src={Pic3Img} alt="学练机细节" />
            </div>
            <div className="dd-column-3">
              <img src={Pic4Img} alt="学练机细节" />
            </div>
          </div>
        </section>
      ) : (
        <section className="dd-root-sm">
          {/* <p className='dd-title'>巧妙设计，细节取胜</p> */}
          <h2>巧妙设计，细节取胜</h2>
          <div className="dd-flex">
            <div className="dd-column-1">
              <div className="dd-column-1-1">
                <img src={Pic1ImgSm} alt="学练机细节" />
              </div>
              <div className="dd-column-1-2">
                <div className="dd-column-1-2-1">
                  <img src={Pic2ImgSm} alt="学练机细节" />
                </div>
                <div className="dd-column-1-2-2">
                  <img src={Pic3ImgSm} alt="学练机细节" />
                </div>
              </div>
            </div>
            <div className="dd-column-2">
              <img src={Pic4ImgSm} alt="学练机细节" />
            </div>
          </div>
        </section>
      )}
    </>
  )
}
