import React from 'react'
import LeftImg from './assets/left.png'
import RightImg from './assets/right.png'
import './SolvePuzzles.less'

export default function SolvePuzzles({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="sps-root-lg">
          <h2>破解益智谜题，塑造底层能力</h2>
          <h3>
            有效培养孩子4大学习底层能力：空间观察能力、逻辑思维能力、计算思维能力、专注力。
          </h3>
          <div className="sps-flex">
            <div className="sps-left">
              <img src={LeftImg} alt="问小猿" />
            </div>
            <div className="sps-right">
              <img src={RightImg} alt="小猿插画" />
            </div>
          </div>
        </section>
      ) : (
        <section className="sps-root-sm">
          <h2>破解益智谜题，塑造底层能力</h2>
          <h3>
            有效培养孩子4大学习底层能力：空间观察能力、逻辑思维能力、计算思维能力、专注力。
          </h3>
          <div className="sps-flex">
            <div className="sps-top">
              <img src={LeftImg} alt="问小猿" />
            </div>
            <div className="sps-bottom">
              <img src={RightImg} alt="小猿插画" />
            </div>
          </div>
        </section>
      )}
    </>
  )
}
