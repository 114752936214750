import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import store from '@/store'
import App from '@/App'
import 'normalize.css'
import 'index.css'
import '@/styles/base.less'
import '@/styles/overload.css'

dayjs.locale('zh-cn')

ReactDOM.render(
  <Provider store={store.Instance}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
