import React, { useCallback, useEffect, useState } from 'react'
import {
  xxlSize,
  xlSize,
  lgSize,
  smSize,
  navSubHeightXxl,
  navSubHeightXl,
  navSubHeightLg,
  navSubHeightSm,
  comments,
} from '@/constants/index'
import useWindowResize from '@/utils/useWindowResize'

export type ScrollCommentProps = {
  id: string
  windowLg: boolean
  handler?: (el: any) => void
}

const getScrollHeight = (offsetTop: number, width: number) => {
  if (width > xxlSize) {
    return offsetTop - navSubHeightXxl - 30
  }
  if (width > xlSize) {
    return offsetTop - navSubHeightXl - 30
  }

  if (width > lgSize) {
    return offsetTop - navSubHeightLg - 30
  }

  if (width > smSize) {
    return offsetTop - navSubHeightSm - 40
  }
  return offsetTop - navSubHeightSm - 60
}

export default function useScrollComment({
  id,
  windowLg,
  ...rest
}: ScrollCommentProps) {
  const [index, setIndex] = useState(0)

  const { windowWidth } = useWindowResize()

  useEffect(() => {
    setIndex(comments.findIndex((x) => x.id === id))
  }, [])

  const handleClickComment = useCallback(() => {
    const container = document.getElementById(id)

    if (container) {
      if (rest.handler) {
        rest.handler(container)
      } else {
        const allContainer = document.querySelectorAll('.comments')
        allContainer.forEach((x) => {
          x.classList.remove('selected-content')
        })
        container.classList.add('selected-content')
        const height = getScrollHeight(container.offsetTop, windowWidth)

        window.scrollTo({
          top: height,
          behavior: 'smooth',
        })
      }
    } else {
      throw Error('no comment container')
    }
  }, [])

  return { handleClickComment, commentIndex: index + 1 }
}
