import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { frog, object2Map, getType } from '@/services/frog'
import LogoIcon from './assets/logo-icon.png'
import NavIcon from './assets/nav-icon.png'
import NavIconCancel from './assets/nav-icon-cancel.png'
import './Navbar.less'

const navigationList = [
  { name: '首页', url: '/', frogName: 'home' },
  { name: '新闻中心', url: '/news', frogName: 'news' },
  { name: '关于我们', url: '/about-us', frogName: 'aboutUs' },
  {
    name: '加入我们',
    url: 'https://app.mokahr.com/apply/fenbi/6156#/',
    frogName: 'joinUs',
  },
]

export default function NavBar({
  windowType,
  windowLg,
}: {
  windowType: string
  windowLg: boolean
}) {
  const history = useHistory()

  const handleClickNav = (nav: any) => {
    const { url, frogName } = nav
    frog('/click/megrezOfficialPage/firstNavigation/button', {
      keyValues: object2Map({
        type: getType(),
        name: frogName,
      }),
    })
    document.body.classList.remove('nav-scroll-disabled')
    if (history.location.pathname === url) {
      window.location.reload()
    } else if (frogName === 'joinUs') {
      window.open('https://app.mokahr.com/apply/fenbi/6156#/')
    } else {
      history.push(url)
    }
  }

  const [showNavList, setNavList] = useState(false)

  const handleClick = useCallback(() => {
    setNavList((pre) => {
      if (!pre) {
        if (windowType !== 'pc') {
          document.body.classList.add('nav-scroll-disabled')
        }
        frog('/click/megrezOfficialPage/firstNavigation/more')
      } else {
        document.body.classList.remove('nav-scroll-disabled')
      }
      return !pre
    })
  }, [windowType])

  useEffect(() => {
    frog('/event/megrezOfficialPage/firstNavigation/display', {
      keyValues: object2Map({
        type: getType(),
        isMerge: windowLg ? 0 : 1,
      }),
    })
  }, [])

  function onClickNavContainer() {
    document.body.classList.remove('nav-scroll-disabled')
    setNavList(false)
  }

  function onClickLogo() {
    handleClickNav({
      frogName: 'logo',
      url: '/',
    })
  }

  return (
    <>
      {windowLg ? (
        <div className="bar-lg">
          <div className="logo-lg">
            <img
              className="logo-img-lg"
              src={LogoIcon as string}
              onClick={onClickLogo}
              alt="logo"
            />
          </div>
          <div className="navigation-lg">
            {navigationList.map((nav, index) => (
              <div
                className="navigation-item-lg"
                key={index}
                onClick={() => handleClickNav(nav)}
              >
                {nav.name}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="bar-sm">
          <div className="logo-sm">
            <img
              className="logo-img-sm"
              onClick={onClickLogo}
              src={LogoIcon as string}
              alt="logo"
            />
          </div>

          <div className="navigation-sm">
            <div className="nav-icon-sm" onClick={handleClick}>
              <img
                className="nav-icon-img-sm"
                src={showNavList ? NavIconCancel : NavIcon}
                alt="icon"
              />
            </div>

            {showNavList ? (
              <div
                className="navigation-item-container-sm"
                onClick={onClickNavContainer}
              >
                {navigationList.map((nav, index) => (
                  <div key={index}>
                    <div
                      className="navigation-item-sm"
                      onClick={() => handleClickNav(nav)}
                    >
                      {nav.name}
                    </div>
                    {index !== navigationList.length - 1 ? (
                      <div className="nav-divider-sm" />
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}
