import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import useScrollComment from '@/utils/useScrollComment'
import ArrowLeft from './assets/arrow-left.png'
import ArrowRight from './assets/arrow-right.png'
import Img1 from './assets/img1.png'
import Img2 from './assets/img2.png'
import Img3 from './assets/img3.png'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/swiper.min.css'
import './accordion.less'

SwiperCore.use([Navigation])

function Accordion({ windowLg }: { windowLg: boolean }) {
  const { handleClickComment, commentIndex } = useScrollComment({
    id: 'thickness',
    windowLg,
  })

  return (
    <section
      style={{
        overflow: 'hidden',
      }}
    >
      <h2>随时随地，畅快学练</h2>
      <div className="accordion-desc">
        <div className="accordion-main">
          <div className="accordion-desc-item">
            <div className="desc-content-container">
              <div className="desc-content">超轻机身</div>
              <div className="desc-content">445g</div>
            </div>
            <div className="desc-divider" />
          </div>
          <div className="accordion-desc-item">
            <div className="desc-content-container">
              <div className="desc-content">超薄便携</div>
              <div className="desc-content">
                6.9mm
                <sup className="desc-tip" onClick={handleClickComment}>
                  {commentIndex}
                </sup>
              </div>
            </div>
            <div className="desc-divider" />
          </div>
          <div className="accordion-desc-item">
            <div className="desc-content-container">
              <div className="desc-content">轻柔护机</div>
              <div className="desc-content">定制皮套</div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-container">
        <Swiper
          slidesPerView={windowLg ? 2.15 : 1.2}
          navigation={{ prevEl: '.left-arrow', nextEl: '.right-arrow' }}
        >
          <SwiperSlide>
            <img
              className="accordion-img"
              src={Img1}
              alt="练习册不再堆积如山"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img className="accordion-img" src={Img2} alt="兴趣使然" />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="accordion-img accordion-img-last"
              src={Img3}
              alt="解放双手"
            />
          </SwiperSlide>
        </Swiper>
        <div>
          {windowLg ? (
            <div className="left-arrow">
              <img className="left-img" src={ArrowLeft} alt="Previous" />
            </div>
          ) : null}
          {windowLg ? (
            <div className="right-arrow">
              <img className="right-img" src={ArrowRight} alt="Next" />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default React.memo(Accordion)
