import React, { useState, useCallback, useRef } from 'react'
import CloseIcon from './assets/close.png'
import './video.less'
import PlayIcon from './assets/play.png'

const videoSrcCut =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20231101/video1_1698828131244_ee57f3-f53e-a45e-c166-84af5437abca.mp4'
const videoSrc =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20231101/video1_1698828131244_ee57f3-f53e-a45e-c166-84af5437abca.mp4'

function VideoWithModal({ windowLg }: { windowLg: boolean }) {
  const [open, setOpen] = useState(false)

  const onCloseModal = useCallback((e) => {
    if (e.currentTarget === e.target) setOpen(false)
  }, [])
  const handlePlay = useCallback(() => {
    setOpen(true)
  }, [])

  const [playIconShow, setPlayIconShow] = useState(true)

  const videoRef = useRef<any>(null)

  const handleMobilePlay = useCallback(() => {
    setPlayIconShow(false)
    setTimeout(() => {
      videoRef.current.currentTime = 0
      videoRef.current.play()
    }, 0)
  }, [])

  return (
    <>
      {windowLg ? (
        <section className="player-container">
          <video
            className="video"
            playsInline
            muted
            autoPlay
            loop
            src={videoSrcCut}
            width="100%"
          />
          <img
            className="player-icon"
            src={PlayIcon}
            onClick={handlePlay}
            alt="播放"
          />
          {open && (
            <div className="video-modal-wrap" onClick={onCloseModal}>
              <div className="video-modal">
                <img
                  src={CloseIcon}
                  className="video-close-icon"
                  onClick={onCloseModal}
                  alt="关闭"
                />
                <video
                  className="video"
                  playsInline
                  muted
                  autoPlay
                  loop
                  controls
                  src={videoSrc}
                  width="100%"
                />
              </div>
            </div>
          )}
        </section>
      ) : (
        <section className="player-container-sm">
          <div className="video-modal">
            <>
              <video
                ref={videoRef}
                className="player-sm"
                muted
                autoPlay
                loop
                src={videoSrc}
                width="100%"
                playsInline
                controls={!playIconShow}
              />
              {playIconShow ? (
                <img
                  className="player-icon"
                  src={PlayIcon}
                  onClick={handleMobilePlay}
                  alt="播放"
                />
              ) : null}
            </>
          </div>
        </section>
      )}
    </>
  )
}

export default React.memo(VideoWithModal)
