import React from 'react'
import style from './SmartCameraVideo.module.less'

const videoSrc =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20240514/smart_camera_pc_1715653479252_8b72001-136b-1cc-bff8-c24c56f63bb.mp4'

const videoSrcSm =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20240514/smart_camera_mobile_1715653564344_7edc1c-0f3f-25b3-d51c-a8efb5bcb51a.mp4'

export default function SmartCameraVideo({ windowLg }: { windowLg: boolean }) {
  return (
    <section className={style.root}>
      <h2>一键智慧拍，意想不到大妙用</h2>
      <video
        className={style.player}
        muted
        autoPlay
        loop
        playsInline
        src={windowLg ? videoSrc : videoSrcSm}
        width="100%"
      />
    </section>
  )
}
