import React from 'react'
import pic from './assets/pic.png'
import picSm from './assets/picSm.png'
import './BookReader.less'

export default function BookReader({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="br-root-lg">
          <h2>天生阅读器，好书要畅读</h2>
          <h3>
            官方内置中小学必读书目，也可自由阅读其他文件
            <br />
            多档位可调阅读灯，恣意畅享睡前阅读时光
          </h3>
          <div className="br-bg">
            <img src={pic} alt="畅读好书" />
          </div>
        </section>
      ) : (
        <section className="br-root-sm">
          <h2>天生阅读器，好书要畅读</h2>
          <h3>
            官方内置中小学必读书目，也可自由阅读其他文件
            <br />
            多档位可调阅读灯，恣意畅享睡前阅读时光
          </h3>
          <div className="br-bg">
            <img src={picSm} alt="畅读好书" />
          </div>
        </section>
      )}
    </>
  )
}
