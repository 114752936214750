import React from 'react'
import './Specifications.less'
import useScrollComment from '@/utils/useScrollComment'

export default function Specifications({ windowLg }: { windowLg: boolean }) {
  const { handleClickComment, commentIndex } = useScrollComment({
    id: 'standby',
    windowLg,
  })

  return (
    <>
      {windowLg ? (
        <section className="sp-root-lg">
          <h2>强芯超轻，为练习而生</h2>

          <div className="sp-flex sp-layer-1">
            <div className="sp-grid sp-layer-1-1">
              <div>
                <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                  练学读写，存储无忧
                </p>
                <div className="sp-row-flex">
                  <div>
                    <p
                      className="sp-h1"
                      style={{ fontSize: '3.19vw', marginBottom: '0.56vw' }}
                    >
                      64GB
                    </p>
                    <p className="sp-h2">超大内存</p>
                  </div>
                  <p
                    className="sp-h1"
                    style={{ fontSize: '3.19vw', marginBottom: '0.56vw' }}
                  >
                    +
                  </p>
                  <div>
                    <p
                      className="sp-h1"
                      style={{ fontSize: '3.19vw', marginBottom: '0.56vw' }}
                    >
                      5GB
                    </p>
                    <p className="sp-h2">云空间</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="sp-layer-1-2">
              <div className="sp-grid sp-layer-1-2-1">
                <div>
                  <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                    运行速率的
                    <br />
                    硬核保障
                  </p>
                  <p className="sp-h1">高通八核芯片</p>
                </div>
              </div>
              <div className="sp-grid sp-layer-1-2-2">
                <div>
                  <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                    超轻机身
                    <br />
                    超级便携
                  </p>
                  <p className="sp-h1">445g</p>
                </div>
              </div>
            </div>

            <div className="sp-layer-1-3">
              <div className="sp-grid sp-layer-1-3-1">
                <div>
                  <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                    超长续航
                    <br />
                    稳定待机长达
                  </p>
                  <p className="sp-h1">
                    40天
                    <sup
                      onClick={handleClickComment}
                      style={{ cursor: 'pointer' }}
                    >
                      {commentIndex}
                    </sup>
                  </p>
                </div>
              </div>
              <div className="sp-grid sp-layer-1-3-2">
                <div>
                  <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                    全屏99.9%区域
                    <br />
                    手写精度偏差小于
                  </p>
                  <p className="sp-h1">0.1mm</p>
                </div>
              </div>
            </div>
          </div>

          <div className="sp-flex sp-layer-2">
            <div className="sp-grid sp-layer-2-1">
              <div>
                <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                  墨水屏高清分辨率
                </p>
                <p className="sp-h1">1872 x 1404</p>
              </div>
            </div>

            <div className="sp-grid sp-layer-2-2">
              <div>
                <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                  手写笔磁吸收纳，不易丢
                </p>
                <p className="sp-h1" style={{ marginBottom: '0.56vw' }}>
                  磁吸收纳
                </p>
              </div>
            </div>

            <div className="sp-grid sp-layer-2-3">
              <div>
                <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                  磁吸保护套，折叠可当阅读架
                </p>
                <p className="sp-h1">有“备”而来</p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="sp-root-sm">
          <h2>强芯超轻，为练习而生</h2>

          <div className="sp-flex sp-layer-1">
            <div>
              <p className="sp-h3">练学读写，存储无忧</p>
              <div className="sp-row-flex">
                <div>
                  <p className="sp-h1">64GB</p>
                  <p className="sp-h2">超大内存</p>
                </div>
                <p className="sp-h1">+</p>
                <div>
                  <p className="sp-h1">5GB</p>
                  <p className="sp-h2">云空间</p>
                </div>
              </div>
            </div>
            <div>
              <p className="sp-h3">
                超长续航
                <br />
                稳定待机长达
              </p>
              <p className="sp-h1">
                40天
                <sup onClick={handleClickComment} style={{ cursor: 'pointer' }}>
                  {commentIndex}
                </sup>
              </p>
            </div>
          </div>

          <div className="sp-flex sp-layer-2">
            <div>
              <p className="sp-h3" style={{ marginBottom: '0.83vw' }}>
                全屏99.9%区域
                <br />
                手写精度偏差小于
              </p>
              <p className="sp-h1">0.1mm</p>
            </div>
            <div>
              <p className="sp-h3" style={{ marginBottom: '0.83vw' }}>
                运行速率的硬核保障
              </p>
              <p className="sp-h2" style={{ marginBottom: '0.28vw' }}>
                高通
              </p>
              <p className="sp-h1">八核芯片</p>
            </div>
          </div>

          <div className="sp-flex sp-layer-3">
            <div>
              <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                墨水屏高清分辨率
              </p>
              <p className="sp-h1">1872 x 1404</p>
            </div>
            <div>
              <p className="sp-h3" style={{ marginBottom: '0.83vw' }}>
                超轻机身，超级便携
              </p>
              <p className="sp-h1">445g</p>
            </div>
          </div>

          <div className="sp-flex sp-layer-4">
            <div>
              <p className="sp-h1" style={{ marginBottom: '0.56vw' }}>
                有“备”而来
              </p>
              <p className="sp-h3">
                自备磁吸保护套
                <br />
                折叠可当阅读架
              </p>
            </div>
            <div>
              <p className="sp-h3" style={{ marginBottom: '0.56vw' }}>
                手写笔
              </p>
              <p className="sp-h1" style={{ marginBottom: '0.28vw' }}>
                磁吸收纳
              </p>
              <p className="sp-h2">不易丢</p>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
