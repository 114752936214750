import React, { useEffect, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import routes from '@/routes'
import Base from '@/components/Layouts/Base'
import Navbar from '@/components/Layouts/Navbar'
import NavbarSub from '@/components/Layouts/NavbarSub'
import Content from '@/components/Layouts/Content'
import Footer from '@/components/Layouts/Footer'
import useWindowResize from '@/utils/useWindowResize'
import { ApplicationState } from '@/store'
import { smSize, lgSize, xlSize } from '@/constants/index'
import theme from './theme'

interface PropsFromState {
  // TODO:
}

interface PropsFromDispatch {
  // TODO:
}

type AllProps = PropsFromState & PropsFromDispatch

const App: React.FC<AllProps> = () => {
  useEffect(() => {
    // TODO:
  }, [])

  const { windowAspectRatio, windowType, windowWidth } = useWindowResize()

  // useEffect(() => {
  //   // TODO:
  // }, [location])

  useEffect(() => {
    if (
      windowAspectRatio >= 1 &&
      windowAspectRatio <= 1.1 &&
      windowWidth <= lgSize
    ) {
      document.body.classList.add('min-width')
    }
  })

  return (
    <>
      <Base>
        <Content />
        <Footer windowType={windowType} />
      </Base>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    // TODO:
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    // TODO:
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
