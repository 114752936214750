import React, { useEffect } from 'react'
import { frog, object2Map, getType } from '@/services/frog'
import { smSize, lgSize, xlSize } from '@/constants/index'
import Navbar from '@/components/Layouts/Navbar'
import useWindowResize from '@/utils/useWindowResize'
import NewsCard from './newsCard'
import pic1 from './assets/1.png'
import pic2 from './assets/2.png'
import pic3 from './assets/3.png'
import pic4 from './assets/4.png'
import pic5 from './assets/5.png'
import './news.less'

export default function News() {
  const { windowType, windowWidth } = useWindowResize()
  const data = [
    {
      image: pic5,
      title: '北京卫视《北京您早》专题：以高质量教育引领未来',
      tags: ['人工智能', '高质量教育'],
      date: '2023-09-03',
      source: '北京卫视',
      url: 'https://item.btime.com/213dlagajonml5mb0373l30brtl',
    },
    {
      image: pic4,
      title:
        '小猿学练机亮相2023年中国国际服贸会 人工智能前沿探索助力教育数字化创新发展',
      tags: ['服贸会', '人工智能', '教育数字化'],
      date: '2023-09-02',
      source: '央广网',
      url: 'https://tech.cnr.cn/techph/20230902/t20230902_526405900.shtml',
    },
    {
      image: pic3,
      title: '小猿学练机与曲一线达成深度战略合作 数字化“5·3”教辅专区正式上线',
      tags: ['曲一线', '5·3', '战略合作'],
      date: '2023-08-09',
      source: '新华社客户端',
      url: 'https://my-h5news.app.xinhuanet.com/h5/article.html?articleId=74bd02f23a9ac3daf8933ebc3ffd0da3',
    },
    {
      image: pic2,
      title:
        '从“人工智能+教育”到“教育+人工智能” 小猿学练机助力数字教育高质量发展',
      tags: ['人工智能', '数字教育'],
      date: '2023-08-08',
      source: '新华社《经济参考报》',
      url: 'http://www.jjckb.cn/2023-08/08/c_1310736037.htm',
    },
    {
      image: pic1,
      title: '《36KR》专题报道：猿辅导「下注」学练机，一场奇袭式进攻',
      tags: ['小猿学练机', '新品发布会'],
      date: '2023-05-31',
      source: '36KR',
      url: 'https://www.36kr.com/p/2281524099520512',
    },
  ]

  useEffect(() => {
    const el = document.getElementById('navbar-sub')
    el?.classList.add('sticky')

    frog('/event/megrezOfficialPage/news/display', {
      keyValues: object2Map({
        type: getType(),
      }),
    })
  }, [])

  return (
    <div className={windowType === 'pc' ? 'news-lg' : 'news-sm'}>
      <Navbar windowType={windowType} windowLg={windowWidth > xlSize} />
      <div className="news-header">新闻中心</div>
      <div className="news-cards">
        {data &&
          data.map((item: any, idx: number) => (
            <NewsCard {...item} key={idx} windowType={windowType} />
          ))}
      </div>
    </div>
  )
}
