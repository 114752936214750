import React, { useEffect, useState, useCallback } from 'react'
import { smSize, lgSize, xlSize } from '@/constants/index'

export type WindowType = 'pad-portrait' | 'mob' | 'pad' | 'pc'

export default function useWindowResize() {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    windowAspectRatio: window.innerWidth / window.innerHeight,
    windowType: 'pc' as WindowType,
  })

  const layout = useCallback(() => {
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight
    const aspectRatio = viewportWidth / viewportHeight

    let type = 'pc'

    if (viewportWidth > smSize && viewportWidth < xlSize && aspectRatio < 1) {
      type = 'pad-portrait'
    } else if (viewportWidth < lgSize && aspectRatio < 1) {
      type = 'mob'
    } else if (viewportWidth < xlSize && aspectRatio > 1.1) {
      type = 'pad'
    } else if (viewportWidth >= xlSize) {
      type = 'pc'
    } else {
      type = 'pc'
    }

    console.log(
      `${viewportWidth} x ${viewportHeight}`,
      aspectRatio.toFixed(3),
      type
    )
    return type as WindowType
  }, [])

  const updateSize = useCallback(() => {
    setWindowSize({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      windowAspectRatio: window.innerWidth / window.innerHeight,
      windowType: layout(),
    })
  }, [layout])

  useEffect(() => {
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return windowSize
}
