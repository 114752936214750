import React, { useState, useCallback } from 'react'

export type SystemCardPropsType = {
  id: number
  title: any
  content: string
  isHover: boolean
  icon: string
  handleClick: (id: number) => void
}

function SystemCard(props: SystemCardPropsType) {
  const { title, content, icon, handleClick, id, isHover } = props

  const onClickItem = useCallback(() => {
    handleClick(id)
  }, [id, handleClick])

  const hoverStyle = {
    background: '#fff',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
    color: '#ff5500',
  }
  return (
    <div
      className="system-card"
      onClick={onClickItem}
      style={isHover ? hoverStyle : {}}
    >
      <div className="system-card-flex">
        <i className={`megrez-icon-${icon}`} />
        <div className="system-title" style={{ color: 'inherit' }}>
          {title}
        </div>
      </div>
      <div className="system-content" style={{ color: 'inherit' }}>
        {content}
      </div>
    </div>
  )
}

export default React.memo(SystemCard)
