import React, { useState, useCallback, useRef } from 'react'
import useInserted from '@/utils/useInserted'
import './video.less'

const videoSrc =
  'https://solar-online.fbcontent.cn/megrez-online/megrez-alkaid/public/20231101/video2_1698828179208_2ef0d52-a2f8-227d-616c-f83b16f34c2.mp4'

function VideoWithContent({ windowLg }: { windowLg: boolean }) {
  const playerContent = useRef(null)
  const [animationStart, setAnimationStart] = useState(false)
  const insertedHandler = useCallback((ratio: number) => {
    setAnimationStart(ratio > 0.2)
  }, [])

  useInserted({
    target: playerContent,
    handler: insertedHandler,
  })

  return (
    <>
      {windowLg ? (
        <section className="player-container-bg">
          <video muted autoPlay playsInline loop src={videoSrc} width="100%" />
          <div
            className="player-bottom-content-container-bg"
            ref={playerContent}
          >
            <div
              className={`${
                animationStart
                  ? 'animation-fade-in content-bottom-bg'
                  : 'opacity-0'
              }`}
            >
              与铅笔一致的粗细度，硬毛毡材质的笔芯，智能橡皮擦除笔迹，与孩子的持笔习惯完美匹配
              <br />
              配重靠下，4096级压感，定制低阻尼感，超低延时，让孩子的每一笔都流畅无阻
            </div>
          </div>
        </section>
      ) : (
        <section className="player-container-sm">
          <video
            playsInline
            className="player-sm"
            muted
            autoPlay
            loop
            src={videoSrc}
            width="100%"
          />
          <div
            className="player-bottom-content-container-sm"
            ref={playerContent}
          >
            <div className="content-bottom-sm">
              与铅笔一致的粗细度，硬毛毡材质的笔芯，智能橡皮擦除笔迹，与孩子的持笔习惯完美匹配。
              配重靠下，4096级压感，定制低阻尼感，超低延时，让孩子的每一笔都流畅无阻
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default React.memo(VideoWithContent)
