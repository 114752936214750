import React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import useScrollComment from '@/utils/useScrollComment'
import Pad1Img from './assets/pad1.png'
import Pad2Img from './assets/pad2.png'
import './LikePaper.less'

const animationDuration = 400
const animationDelay = 400

export default function LikePaper({
  windowType,
  windowLg,
}: {
  windowType: string
  windowLg: boolean
}) {
  const {
    handleClickComment: handleClickComment7,
    commentIndex: commentIndex7,
  } = useScrollComment({
    id: '861',
    windowLg,
  })

  const {
    handleClickComment: handleClickComment8,
    commentIndex: commentIndex8,
  } = useScrollComment({
    id: 'nib',
    windowLg,
  })

  return (
    <div className={`lp--${windowType}`}>
      {windowLg ? (
        <section className="animate lp-root-lg">
          <Controller>
            <div>
              <Scene
                triggerHook="onLeave"
                offset={-64}
                duration={animationDuration * 2 + animationDelay * 2}
                pin
              >
                {(progress: any) => (
                  <div className="lp-main">
                    <div className="lp-left">
                      <Timeline
                        target={
                          <img
                            className="lp-pad lp-visible"
                            src={Pad1Img}
                            alt="天生不发光"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                        <Tween to={{ opacity: 0 }} duration={animationDelay} />
                      </Timeline>

                      <Timeline
                        target={
                          <div className="lp-pan-combine lp-not-visible">
                            <img
                              className="lp-pad"
                              src={Pad2Img}
                              alt="纸感书写体验"
                            />
                          </div>
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ opacity: 0 }}
                          to={{ opacity: 1 }}
                          delay={animationDelay + animationDuration}
                          duration={animationDuration}
                        />
                        <Tween to={{ opacity: 1 }} duration={animationDelay} />
                      </Timeline>
                    </div>

                    <div className="lp-right">
                      <Timeline
                        target={
                          <div className="lp-text-area">
                            <p className="lp-title">天生不发光，一如纸质书籍</p>
                            <p className="lp-desc">
                              10.3英寸类纸护眼墨水屏，86.1%高屏占比
                              <sup
                                onClick={handleClickComment7}
                                style={{ cursor: 'pointer' }}
                              >
                                {commentIndex7}
                              </sup>
                              ，视觉效果无限接近
                              <br />
                              纸质书籍。0蓝光，0频闪，真护眼。
                            </p>
                          </div>
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 1,
                            translateX: '-50%',
                            translateY: '-50%',
                          }}
                          to={{
                            opacity: 0,
                            translateX: '-50%',
                            translateY: '-100%',
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                        <Tween to={{ opacity: 0 }} duration={animationDelay} />
                      </Timeline>

                      <Timeline
                        target={
                          <div className="lp-text-area">
                            <p className="lp-title">纸感书写体验，上手零门槛</p>
                            <p className="lp-desc">
                              超高精度笔迹追踪，全屏99.9%区域手写精度偏差小于0.1mm
                              <sup
                                onClick={handleClickComment8}
                                style={{ cursor: 'pointer' }}
                              >
                                {commentIndex8}
                              </sup>
                              <br />
                              纸上怎么写，机上怎么练。
                            </p>
                          </div>
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 0,
                            translateX: '-50%',
                            translateY: '50%',
                          }}
                          to={{
                            opacity: 1,
                            translateX: '-50%',
                            translateY: '-50%',
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                        <Tween to={{ opacity: 1 }} duration={animationDelay} />
                      </Timeline>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      ) : (
        <section className="animate lp-root-sm">
          <Controller>
            <div>
              <Scene triggerHook="onLeave" offset={-64} duration={1000} pin>
                {(progress: any) => (
                  <div className="lp-main">
                    <div className="lp-top">
                      <Timeline
                        target={
                          <div className="lp-text-area">
                            <p className="lp-title">
                              天生不发光
                              <br />
                              一如纸质书籍
                            </p>
                            <p className="lp-desc">
                              10.3英寸类纸护眼墨水屏，86.1%高屏占比
                              <sup
                                onClick={handleClickComment7}
                                style={{ cursor: 'pointer' }}
                              >
                                {commentIndex7}
                              </sup>
                              ，视觉效果无限接近纸质书籍。0蓝光，0频闪，真护眼。
                            </p>
                          </div>
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ opacity: 1, y: 0 }}
                          to={{ opacity: 0, y: '-21.52vw' }}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <div className="lp-text-area">
                            <p className="lp-title">
                              纸感书写体验
                              <br />
                              上手零门槛
                            </p>
                            <p className="lp-desc">
                              超高精度笔迹追踪，全屏99.9%区域手写精度偏差小于0.1mm
                              <sup
                                onClick={handleClickComment8}
                                style={{ cursor: 'pointer' }}
                              >
                                {commentIndex8}
                              </sup>
                              。 纸上怎么写，机上怎么练。
                            </p>
                          </div>
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ opacity: 0, y: '21.52vw' }}
                          to={{ opacity: 1, y: 0 }}
                          duration={animationDuration}
                        />
                      </Timeline>
                    </div>

                    <div className="lp-bottom">
                      <Timeline
                        target={
                          <img
                            className="lp-pad lp-visible"
                            src={Pad1Img}
                            alt="天生不发光"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <div className="lp-pan-combine lp-not-visible">
                            <img
                              className="lp-pad"
                              src={Pad2Img}
                              alt="纸感书写体验"
                            />
                          </div>
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{ opacity: 0 }}
                          to={{ opacity: 1 }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      )}
    </div>
  )
}
