import React from 'react'
import LeftImg from './assets/left.png'
import RightImg from './assets/right.png'
import MicroPhone from './assets/microphone.webp'
import './AccompanyRead.less'

export default function AccompanyRead({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="ar-root-lg">
          <h2>天马行空问“小猿”，沉浸伴读好伙伴</h2>
          <div className="ar-flex">
            <div className="ar-left">
              <img src={LeftImg} alt="问小猿" />
            </div>
            <div className="ar-right">
              <img src={RightImg} alt="小猿插画" />
              <div className="microphone">
                <img src={MicroPhone} />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="ar-root-sm">
          <h2>天马行空问“小猿”，沉浸伴读好伙伴</h2>
          <div className="ar-flex">
            <div className="ar-left">
              <img src={LeftImg} alt="问小猿" />
            </div>
            <div className="ar-right">
              <img src={RightImg} alt="小猿插画" />
              <div className="microphone">
                <img src={MicroPhone} />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
