import React from 'react'
import BgImg from './assets/bg.png'
import BgImgSm from './assets/bg-sm.png'
import './Resources.less'

export default function Resources({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="res-root-lg">
          <h2>10亿+高品质题库资源，小学3亿+题目覆盖精讲视频</h2>
          <h3>
            智能出题，只出好题。猿辅导11年专业教研积累，真人老师启发式讲解，讲通，讲透，讲会。
          </h3>
          <img src={BgImg} alt="启发式讲题视频" />
        </section>
      ) : (
        <section className="res-root-sm">
          <h2>
            10亿+高品质题库资源
            <br />
            小学3亿+题目覆盖精讲视频
          </h2>
          <h3>
            智能出题，只出好题。猿辅导11年专业教研积累，真人老师启发式讲解，讲通，讲透，讲会。
          </h3>
          <img src={BgImgSm} alt="启发式讲题视频" />
        </section>
      )}
    </>
  )
}
