import React from 'react'
import './fileComponent.less'
import PDFImg from './assets/pdf.png'
import WordImg from './assets/word.png'
import Img from './assets/image.png'
import FileTypesSm from './assets/file-types-sm.png'
import Phone from './assets/phone.png'
import Equipment from './assets/equipment.png'
import ArrowWebp from './assets/arrow.webp'
import Bg from './assets/bg.png'
import BgSm from './assets/bg-sm.png'

function FileComponent({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section
          className="file-container-bg"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          <div className="file-main">
            <div className="file-left">
              <div className="title">文件自由传，练读随心选</div>
              <div className="content">
                搜索“小猿智能设备”微信小程序
                <br />
                即可自由上传文件
                <br />
                练习资源阅读资源清晰分类
                <br />
              </div>
              <div className="file-type">
                <div className="file-type-title">支持文件类型</div>
                <div className="icon-group">
                  <div className="icon-item">
                    <img src={WordImg} alt="word" />
                    Word
                  </div>
                  <div className="icon-item">
                    <img src={Img} alt="图片" />
                    图片
                  </div>
                  <div className="icon-item">
                    <img src={PDFImg} alt="PDF" />
                    PDF
                  </div>
                </div>
              </div>
            </div>
            <div className="file-right-bg">
              <div className="phone-bg">
                <img src={Phone} alt="手机小猿智能设备家长端小程序" />
              </div>
              <div className="arrow-bg">
                <img src={ArrowWebp} />
              </div>
              <div className="equip-bg">
                <img src={Equipment} alt="小猿学练机" />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section
          className="file-container-sm"
          style={{ backgroundImage: `url(${BgSm})` }}
        >
          <div className="title">文件自由传，练读随心选</div>
          <div className="content">
            搜索“小猿智能设备”微信小程序
            <br />
            即可自由上传文件
            <br />
            练习资源阅读资源清晰分类
            <br />
          </div>
          <div className="file-img-container-sm">
            <div className="phone-sm">
              <img src={Phone} alt="手机小猿智能设备家长端小程序" />
            </div>
            <div className="arrow-sm">
              <img src={ArrowWebp} />
            </div>
            <div className="equip-sm">
              <img src={Equipment} alt="小猿学练机" />
            </div>
          </div>
          <div className="file-types-sm">
            支持文件类型
            <div className="icon-group">
              <img src={WordImg} alt="word" />
              <img src={Img} alt="图片" />
              <img src={PDFImg} alt="PDF" />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default React.memo(FileComponent)
