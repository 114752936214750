import { CustomRouteConfig } from '@/types/common'
import Home from '@/pages/home/home'
import Specs from '@/pages/specs/specs'
import AboutUs from '@/pages/aboutUs/aboutUs'
import News from '@/pages/news/news'

const homeRouters: CustomRouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: Home,
    nav: false,
    meta: {
      title: '小猿学练机',
    },
  },
]
const specsRouters: CustomRouteConfig[] = [
  {
    path: '/specs',
    name: 'specs',
    exact: true,
    component: Specs,
    nav: false,
    meta: {
      title: '规格参数',
    },
  },
]
const aboutUsRouters: CustomRouteConfig[] = [
  {
    path: '/about-us',
    name: 'about-us',
    exact: true,
    component: AboutUs,
    nav: false,
    meta: {
      title: '关于我们',
    },
  },
]
const newsRouters: CustomRouteConfig[] = [
  {
    path: '/news',
    name: 'news',
    exact: true,
    component: News,
    nav: false,
    meta: {
      title: '新闻中心',
    },
  },
]

const routers = [
  ...homeRouters,
  ...specsRouters,
  ...aboutUsRouters,
  ...newsRouters,
]

export default routers
