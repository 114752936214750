import React from 'react'
import BgImg from './assets/bg.png'
import BgImgSm from './assets/bg-sm.png'
import './ClosedSystem.less'

export default function ClosedSystem({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      {windowLg ? (
        <section className="cs-root-lg">
          <h2>封闭式系统，专注再升级</h2>
          <h3>沉浸专注无干扰，自律养成第一步</h3>

          <div className="cs-bg">
            <img src={BgImg} alt="封闭式系统" />
          </div>
        </section>
      ) : (
        <section className="cs-root-sm">
          <h2>封闭式系统，专注再升级</h2>

          <div className="cs-bg">
            <img src={BgImgSm} alt="封闭式系统" />
            <p>
              沉浸专注无干扰
              <br />
              自律养成第一步
            </p>
          </div>
        </section>
      )}
    </>
  )
}
