import React, { useState, useCallback, useRef } from 'react'
import useInserted from '@/utils/useInserted'
import Img from './assets/image.png'
import style from './multiple.module.less'

function MultipleAccounts({ windowLg }: { windowLg: boolean }) {
  return (
    <>
      <section className={style.container}>
        <h2>多账户切换，一本就足够</h2>
        <h3>多孩家庭省钱小tip</h3>
        <img src={Img} width="100%" alt="多账户切换" />
      </section>
    </>
  )
}

export default React.memo(MultipleAccounts)
