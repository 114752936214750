import React from 'react'
import { smSize, lgSize, xlSize } from '@/constants/index'
import Navbar from '@/components/Layouts/Navbar'
import useWindowResize from '@/utils/useWindowResize'
import picLg from './assets/pic-lg.png'
import picSm from './assets/pic-sm.png'
import './aboutUs.less'

export default function AboutUs() {
  const { windowType, windowWidth } = useWindowResize()
  const productDesc = {
    title: '关于小猿学练机',
    subTitle: '墨水屏学习平板领导者',
    content: `小猿学练机是行业内首款墨水屏智能学习平板。该设备具备不伤眼、能手写、智能化三大特点，首次实现了学习过程、学习数据、学习内容的全面数字化，是一款离教育的本质更近，真正具有启发性、探究性、有效互动性的学习硬件产品。\n
    墨水屏学习平板具有屏幕不发光的产品特性，光线通过屏幕反射进入孩子的眼睛，视觉效果无限接近纸质书籍，是一款真正做到零蓝光、零频闪的学习平板，从根本上保护孩子的眼睛。\n
    小猿学练机更是最高限度地还原了孩子日常的书写习惯。其采用的独家类纸纹理拓印技术，使屏幕的摩擦系数完美接近印刷纸张。墨水屏模组深度融合Cu Metal Mesh及专属被动电磁感应技术方案，生产工艺复杂度远超液晶显示屏，真正做到全屏99.9%区域手写精度偏差小于0.1mm，完美适配孩子日常的书写场景。小猿学练机自主研发中小学生专属电磁笔，根据孩子手握及手写特点专门定制了4096级压感的书写感受，低阻尼感和超低延时的连续稳定反馈，都让孩子的每一笔如同写在纸上，生动流畅。电磁笔顶端的小红头设计更是专门模仿了木质铅笔顶端的橡皮擦，圈一圈即可擦除，真正实现“平时怎么写，机上怎么练”的原生手写体验。\n
    小猿学练机基于真实的学习场景，深度应用人工智能技术，自主研发了一整套全场景「以练促学」精准学系统。深层拆解10000+细分知识点及1000000+知识关系链路构建的3D知识图谱，与根据孩子学情分析智能绘制的能力图谱高精度匹配，为孩子智能铺设最适合的个性化学习路径。从智能推题、AI批改、错题归纳、错题讲解、举一反三到薄弱点专练，个性化学习路径的底层逻辑实现全面升维。\n
    小猿学练机从学习的具体场景和需求出发，以人为中心，以个性化学习为内核，以尊重学习规律和人的发展规律为引导，通过深度应用人工智能技术，构建全场景智能系统，多维度激活学习数据，真正做到个性化因材施教，为孩子提效，为家长减负。`,
  }
  const companyDesc = {
    title: '关于猿力科技集团',
    content:
      '猿力科技集团是一家专注于教育科技领域的技术公司，承载集团成立十一年的教育科技研发积淀和市场化企业服务经验， 致力于为用户提供体验极致的智能教育产品，让学习者共享科技带来的美好教育新体验。 深耕教育科技领域十一年，猿力科技集团拥有大规模的教育科技研究团队AI Lab，累计研发投入超过100亿人民币。下一个十年，猿力科技集团将持续加大研发投入，在智慧教育的征途上不懈探索，为教育数字化的发展与实现，贡献自己的一份力量。',
  }
  return (
    <>
      <Navbar windowType={windowType} windowLg={windowWidth > xlSize} />
      {windowType === 'pc' ? (
        <div className="aboutUs-container-lg">
          <img className="aboutUs-pic-lg" src={picLg} alt="关于我们" />
          <div className="aboutUs-pic-container">
            <div className="aboutUs-body-lg">
              <div className="aboutUs-product-lg">
                <div className="aboutUs-product-title-lg">
                  {productDesc.title}
                </div>
                <div className="aboutUs-product-subTitle-lg">
                  {productDesc.subTitle}
                </div>
                <div className="aboutUs-product-content-lg">
                  {productDesc.content}
                </div>
              </div>
              <div className="aboutUs-company-lg">
                <div className="aboutUs-company-title-lg">
                  {companyDesc.title}
                </div>
                <div className="aboutUs-company-content-lg">
                  {companyDesc.content}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="aboutUs-container-sm">
          <img className="aboutUs-pic-sm" src={picSm} alt="关于我们" />
          <div className="aboutUs-pic-container-sm">
            <div className="aboutUs-body-sm">
              <div className="aboutUs-product-sm">
                <div className="aboutUs-product-title-sm">
                  {productDesc.title}
                </div>
                <div className="aboutUs-product-subTitle-sm">
                  {productDesc.subTitle}
                </div>
                <div className="aboutUs-product-content-sm">
                  {productDesc.content}
                </div>
              </div>
              <div className="aboutUs-company-sm">
                <div className="aboutUs-company-title-sm">
                  {companyDesc.title}
                </div>
                <div className="aboutUs-company-content-sm">
                  {companyDesc.content}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
