import React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import useScrollComment from '@/utils/useScrollComment'
import Part1 from './assets/part1.png'
import Part1Gray from './assets/part1-gray.png'
import Part2 from './assets/part2.png'
import Part2Gray from './assets/part2-gray.png'
import Part3 from './assets/part3.png'
import Part3Gray from './assets/part3-gray.png'
import MathImg from './assets/math.png'
import EnglishImg from './assets/english.png'
import ChineseImg from './assets/chinese.png'
import PadImg from './assets/pad.png'
import PicSm from './assets/picSm.png'
import BgSm from './assets/bgSm.png'
import Bg from './assets/bg.png'
import './Breakthrough.less'

const animationDuration = 100
const animationDelay = 100

export default function Breakthrough({ windowLg }: { windowLg: boolean }) {
  const { handleClickComment, commentIndex } = useScrollComment({
    id: 'grade',
    windowLg,
  })

  return (
    <>
      {windowLg ? (
        <section className="animate bt-root-lg">
          <Controller>
            <div>
              <Scene triggerHook="onLeave" offset={-64} duration="200%" pin>
                {(progress: any) => (
                  <div className="bt-main">
                    <h2 style={{ zIndex: 11, position: 'relative' }}>
                      专项突破
                      <sup
                        onClick={handleClickComment}
                        style={{ cursor: 'pointer' }}
                      >
                        {commentIndex}
                      </sup>
                      ，强化提升
                    </h2>
                    <div className="bt-wrap">
                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{ opacity: 0 }}
                          to={{ opacity: 1 }}
                          delay={animationDelay}
                          duration={animationDuration * 10}
                        >
                          <div className="bt-pad">
                            <img src={PadImg} alt="小猿学练机" />
                          </div>
                        </Tween>
                      </Timeline>
                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{ opacity: 0 }}
                          to={{ opacity: 1 }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <div className="bg">
                            <img src={Bg} alt="专项突破背景" />
                          </div>
                        </Tween>
                      </Timeline>
                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-subject bt-subject-math"
                            src={MathImg}
                            alt="数学标签"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{
                            css: {
                              translateX: '-40%',
                              translateY: '-150%',
                              scale: 0.8,
                              opacity: 1,
                            },
                          }}
                          to={{
                            css: {
                              translateX: '-46%',
                              translateY: '-115%',
                              scale: 0.45,
                              opacity: 0,
                            },
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-layer-1"
                            src={Part1}
                            alt="数学专项突破"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{
                            css: {
                              translateX: '-46%',
                              translateY: '-115%',
                              scale: 0.45,
                              opacity: 0,
                            },
                          }}
                          to={{
                            css: {
                              translateX: '-46%',
                              translateY: '-115%',
                              scale: 0.45,
                              opacity: 1,
                            },
                          }}
                          delay={animationDelay * 2}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-layer-1"
                            src={Part1Gray}
                            alt="灰色数学专项突破"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-subject bt-subject-chinese"
                            src={ChineseImg}
                            alt="语文标签"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{
                            css: {
                              translateX: '-60%',
                              translateY: '-60%',
                              scale: 0.8,
                              opacity: 1,
                            },
                          }}
                          to={{
                            css: {
                              translateX: '-46%',
                              translateY: '-50%',
                              scale: 0.45,
                              opacity: 0,
                            },
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-layer-2"
                            src={Part2}
                            alt="语文专项突破"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{
                            css: {
                              translateX: '-46%',
                              translateY: '-50%',
                              scale: 0.45,
                              opacity: 0,
                            },
                          }}
                          to={{
                            css: {
                              translateX: '-46%',
                              translateY: '-50%',
                              scale: 0.45,
                              opacity: 1,
                            },
                          }}
                          delay={animationDelay * 2}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-layer-2"
                            src={Part2Gray}
                            alt="灰色语文专项突破"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-subject bt-subject-english"
                            src={EnglishImg}
                            alt="英语标签"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{
                            css: {
                              translateX: '-40%',
                              translateY: '30%',
                              scale: 0.8,
                              opacity: 1,
                            },
                          }}
                          to={{
                            css: {
                              translateX: '-46%',
                              translateY: '20%',
                              scale: 0.45,
                              opacity: 0,
                            },
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-layer-3"
                            src={Part3}
                            alt="英语专项突破"
                          />
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{
                            css: {
                              translateX: '-46%',
                              translateY: '20%',
                              scale: 0.45,
                              opacity: 0,
                            },
                          }}
                          to={{
                            css: {
                              translateX: '-46%',
                              translateY: '20%',
                              scale: 0.45,
                              opacity: 1,
                            },
                          }}
                          delay={animationDelay * 2}
                          duration={animationDuration}
                        >
                          <img
                            className="bt-layer-3"
                            src={Part3Gray}
                            alt="灰色英语专项突破"
                          />
                        </Tween>
                      </Timeline>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      ) : (
        <section
          className="bt-root-sm"
          style={{ backgroundImage: `url(${BgSm})` }}
        >
          <h2>
            专项突破
            <sup onClick={handleClickComment} style={{ cursor: 'pointer' }}>
              {commentIndex}
            </sup>
            ，强化提升
          </h2>
          <div className="bt-body">
            <img src={PicSm} />
          </div>
        </section>
      )}
    </>
  )
}
